import React, { Fragment, useState, useCallback } from 'react';
import moment from 'moment';
import * as GoogleAnalytics from '../../lib/google-analytics';
import MobilekeyMenual from './MobilekeyMenual';
import CheckOut from './CheckOut';

const BookingList = ({
  bookingItem,
  checkOut,
  history,
}) => {
  const [ isOpenModalMobilekeyMenual, setIsOpenModalMobilekeyMenual ] = useState(false);
  const [ isOpenModalCheckOut, setIsOpenModalCheckOut ] = useState(false);

  const openModalMobilekeyMenual = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_mobilekey_menual',
    });
    setIsOpenModalMobilekeyMenual(true);
  }, []);

  const closeModalMobilekeyMenual = useCallback(() => {
    setIsOpenModalMobilekeyMenual(false);
  }, []);

  const openModalCheckout = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_request',
    });
    setIsOpenModalCheckOut(true);
  }, []);

  const closeModalCheckOut = useCallback(() => {
    setIsOpenModalCheckOut(false);
  }, []);

  const roomNoFormatter = (roomNo) => {
    return roomNo ? parseInt(roomNo.slice(roomNo.length - 4)) : roomNo;
  };

  const dayFormatter = (day) => {
    return {
      Sun: '일',
      Mon: '월',
      Tue: '화',
      Wed: '수',
      Thu: '목',
      Fri: '금',
      Sat: '토',
    }[day] || day;
  };

  const setBookingDate = (date) => {
    return `${moment(date).format('YYYY.MM.DD')} (${dayFormatter(moment(date).format('ddd'))})`;
  };

  const setBookingTime = (time) => {
    return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
  };

  return (
    <Fragment>
      <div className='checked-in-background-container'>
        <div className='opacity-background'>
          <span className='welcome-message'>
            {bookingItem.inhsGestName} 고객님,<br/>
            환영합니다.
          </span>
          <div className='booking-id-container'>
            <span className='booking-id-label'>
              예약번호
            </span>
            <span className='booking-id-value'>
              {bookingItem.reservationNo}
            </span>
          </div>
        </div>
      </div>
      <div className='checked-in-booking-card-container'>
        <span className='hotel-name'>
          {bookingItem.propertyName}
        </span>
        <span className='room-name'>
          {roomNoFormatter(bookingItem.roomNo)}
        </span>
        <div className='horizontal-line mt-15 mb-20'></div>
        <div className='userkey-container'>
          <div className='userkey-item'>
            <span className='userkey-item-label'>
              객실 비밀번호
            </span>
            <span className='userkey-item-value'>
              {bookingItem.entranceNo || '-'}
            </span>
          </div>
          <div className='userkey-item'>
            <span className='userkey-item-label'>
              모바일키
              <img className='tip-icon ml-2 mb-4' src='../../assets/images/question-mark.png' alt='' onClick={openModalMobilekeyMenual} />
            </span>
            <span className='userkey-item-value'>
              {bookingItem.mobileRoomKey || '-'}
            </span>
          </div>
        </div>
        <div className='checked-in-booking-period-container'>
          <img className='period-icon mt-2' src='../../assets/images/period.png' alt='' />
          <div className='period-item-container'>
            <div className='period-item mb-8'>
              <span className='period-item-label'>
                체크인
              </span>
              <span className='period-item-value'>
                {bookingItem.arrvDate
                ? `${setBookingDate(bookingItem.arrvDate)} ${bookingItem.arrvPlanTime && bookingItem.arrvPlanTime.length === 4
                  ? setBookingTime(bookingItem.arrvPlanTime)
                  :'15:00'}`
                : '-'}
              </span>
            </div>
            <div className='period-item'>
              <span className='period-item-label'>
                체크아웃
              </span>
              <span className='period-item-value'>
                {bookingItem.deptDate
                ? `${setBookingDate(bookingItem.deptDate)} ${bookingItem.deptPlanTime && bookingItem.deptPlanTime.length === 4
                  ? setBookingTime(bookingItem.deptPlanTime)
                  :'11:00'}`
                : '-'}
              </span>
            </div>
          </div>
        </div>
        <div className='check-out-button-container'>
          <span className='check-out-button' onClick={openModalCheckout}>
            비대면 체크아웃 하기
          </span>
        </div>
        <div className='horizontal-line mt-15 mb-30'></div>
        <div className='checked-in-booking-room-notice'>
          <pre className='fw-default ws-pl fw-300'>
          <span className='fw-500'>{`<셀프 체크인 투숙객 안내 사항>`}</span><br/>
{`전 객실 테라스 오션뷰를 자랑하는 여수 유탑 마리나 호텔&리조트에 방문해 주어 감사드리며, 셀프체크인 이용 안내드립니다.

1. 셀프 체크인으로 발행된 비밀번호는 체크인 15:00, 체크아웃 11:00 기준으로 사용 가능하며, 시간이 초과될 경우 도어록 사용이 어렵습니다.
(패키지, 특가 이용시 체크인,아웃 시간이 변동 될 수 있습니다.)

2. 셀프 체크인을 한 경우 잔여 결제금이 없는 때에만 셀프 체크아웃 가능합니다.

3. 셀프 체크인을 한 경우 부대업장 및 추가 사용 요금 후불 결제는 어려우며 선결제만 가능합니다.

4. 패키지 이용 시 제공 품목을 모르시는 경우 프런트로 연락 부탁드립니다.
- 관광지 쿠폰 및 제공 상품은 프런트에서 수령 가능합니다.

5. 연박, 레이트 체크아웃을 요청하는 경우 프런트로 연락 부탁드립니다.`}<br/><br/>
<span className='fw-500'>{'<투숙객 이벤트>'}</span><br/>
{`1. 1인 조식 뷔페 무료 제공(매일)
2. 미러볼 가든 프리드링크 2잔 무료 제공(매일)
3. 1인 주간 요트 투어 무료 제공(박수 무관 1회)

* 투숙객 이벤트로 제공 되는 상품은 별도의 쿠폰 없이 현장에서 객실번호, 성함 확인 후 이용 가능합니다.

* 투숙객 이벤트 미제공 상품 구매시 이벤트 적용은 어렵습니다.

** 호텔 이용 안내를 위해 체크인 후 발송되는 카카오톡 아이스테이 알림톡을 참고 부탁드립니다.`}<br/><br/>
<span className='fw-500'>문의  061-690-8000</span><br/><br/>
<span className='fw-500'>내선 - 프런트 0번</span>
          </pre>
        </div>
      </div>
      <MobilekeyMenual isOpen={isOpenModalMobilekeyMenual} onClose={closeModalMobilekeyMenual} />
      <CheckOut isOpen={isOpenModalCheckOut} onClose={closeModalCheckOut} checkOut={checkOut} />
    </Fragment>
  );
};

export default BookingList;